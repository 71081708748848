{
  "UserMenuLogoutButtonText": "Logout",
  "InstanceAdminDashboardTitle": "Dashboard",
  "InstanceAdminOrganisationInfoPageTitle": "Organization",
  "InstanceAdminOrganizationsTabTitle": "Organizations",
  "InstanceAdminOrganizationOnboardingTabTitle": "Onboarding",
  "OnboardingLinkValidUntilText": "valid until:",
  "OnboardOrganzationButtonText": "Create",
  "LinkCopiedToClipboardMassage": "Link copied to clipboard!",
  "OrganizationInfoCardDepartments": "Departments:",
  "OrganizationInfoCardAdmins": "Admins:",
  "OrganizationInfoCardUsers": "User:",
  "OrganizationInfoCardResources": "Resources:",
  "OrganizationInfoCardRoles": "Roles:",
  "OrganizationInfoCardJobs": "CRON-Jobs:",
  "OrganizationOffBoardButton": "Offboard Organization",
  "OrganizationOffBoardDialogTitel": "Offboard Organization",
  "OrganizationOffBoardDialogMessage": "Removing an organization will lead to the deletion of all Departments, Admins, User, Resources and CRON-Jobs assoziated with this organization. This will also remove the App Resistration. This action cannot be undone.",
  "AdminDashboardTitle": "Home",
  "AdminDashboardDepartmentCardInfo": "This page gives you an overview of all existing #DepartmentPlural#.",
  "AdminDashboardSettingsCardInfo": "This page gives you access to all settings of your organisation. Also an overview of all admins and the administration of jobs.",
  "AdminSettingsTitle": "Organization Settings",
  "AdminSettingsNavSideBarTitle": "Settings",
  "AdminSettingsTenantsTabTitle": "AAD Tenants",
  "AdminSettingsAddTenantButtonText": "Assign Tenant",
  "AdminSettingsAdminsTabTitle": "Admins",
  "AdminSettingsAddAdminButtonText": "Add Admin",
  "AdminSettingsJobsTabTitle": "Jobs",
  "AdminSettingsAddJobButtonText": "Add Job",
  "AdminSettingsLogsTitle": "Logs",
  "AdminSettingsLogsNoDataErrorText": "No data matching the filter",
  "AdminSettingsConfigTabTitle": "Configuration",
  "AdminSettingsConfigEMailSection": "Invitation",
  "AdminSettingsAboutTitle": "About",
  "AdminSettingsAboutProductInformation": "Product Information",
  "AdminSettingsAboutGenerel": "General",
  "DepartmentDetailResourcesTabTitle": "Resources",
  "DepartmentDetailAddButtonText": "Add",
  "DepartmentDetailDescriptionUpdateButtonText": "Update Description",
  "InviteSelectTenant": "Tenant",
  "InviteAdminHeader": "Invite #AdminSingular#",
  "InviteUserHeader": "Invite #UserSingular#",
  "InviteSuggestionsHeader": "Suggestions",
  "InviteSelectionHeader": "Selected",
  "DepartmentCreateButton": "Add #DepartmentSingular#",
  "DepartmentCreateTitle": "Create #DepartmentSingular#",
  "DepartmentCreateDepartmentName": "Name",
  "DepartmentCreateDepartmentDescription": "Description",
  "AddResourceTitle": "Add Resource",
  "AddResourceSelectTenantStepTitle": "Select Tenant",
  "AddResourceSelectSubscriptionStepTitle": "Select Subscription",
  "AddResourceSelectResourceGroupStepTitle": "Select Resource Group",
  "AddResourceSelectResourceStepTitle": "Select Resource",
  "AddResourceSelectConfirmationStepTitle": "Confirmation",
  "AddResourceSelectTenantInfoText": "The tenant provides capabilities for identity and access management to resources of your organization. An identity object represents a human or a non-human identity that can be used to authenticate and authorize access to a resource.",
  "AddResourceSelectSubscriptionInfoText": "The subscription that contains the desired resource.",
  "AddResourceSelectResourceGroupInfoText": "Recource groups act as folders for your resources.",
  "AddResourceConfirmationTenant": "Tenant: ",
  "AddResourceConfirmationSubscription": "Subscription: ",
  "AddResourceConfirmationResourceGroup": "Resource Group: ",
  "AddResourceConfirmationResource": "Resource: ",
  "AddResourceConfirmationAddResourceButtonText": "Add Resource",
  "AddJobTitle": "Create Job",
  "EditJobTitle": "Edit Job",
  "JobsDialogJobData": "Job Data",
  "JobsDialogJobName": "Job Name",
  "JobsDialogResourceType": "Resource Type",
  "JobsDialogAction": "Action",
  "JobsDialogTiming": "Timing",
  "JobsDialogTimingMessage": "Please select a timing option",
  "JobsDialogTimingDaily": "Daily",
  "JobsDialogTimingDailyEvery": "Every",
  "JobsDialogTimingWeekly": "Weekly",
  "JobsDialogTimingMonthly": "Monthly",
  "JobsDialogTimingEvery": "every",
  "JobsDialogTimingDays": "Day(s)",
  "JobsDialogTimingEveryWeekday": "Every Weekday(MON-FRI)",
  "JobsDialogTimingEveryWeekOn": "Every week on",
  "JobsDialogTimingOnThe": "On the",
  "JobsDialogTimingDay": "Day",
  "JobsDialogTimingMonths": "Month(s)",
  "JobsDialogTimingWeek": "Week",
  "JobsDialogTimingHours": "Hour",
  "JobsDialogTimingAt": "At",
  "JobsDialogResources": "Resources",
  "JobsDialogResourcesSelectDepartments": "Select Departments",
  "JobsDialogResourcesName": "Resource Name",
  "JobsDialogCreateJobButtonText": "Create Job",
  "JobsDialogUpdateTimingButtonText": "Update Timing",
  "JobsDialogUpdateResourcesButtonText": "Update Resources",
  "UserDetailSelectRolesPlaceholder": "Select Roles",
  "AddTenantTitle": "Add Tenant",
  "AddTenantTenantStepTitle": "Tenant Details",
  "AddTenantClientIdStepTitle": "Client ID",
  "AddTenantClientSecretStepTitle": "Client Secret",
  "AddTenantConfirmStepTitle": "Confirmation",
  "AddTenantTenantInfoText": "The tenant provides capabilities for identity and access management to resources of your organization. An identity object represents a human or a non-human identity that can be used to authenticate and authorize access to a resource.",
  "AddTenantClientIdInfoText": "The application/client id of your Azure Active Directory application. To get the application/client id create an app registration in Azure Active Directory.",
  "AddTenantClientSecretInfoText": "A gernerated secret for your Azure Active Directory application. If you do not have one yet go to your app registration and under Manage / Certificates & secrets click New client secret.",
  "AddTenantConfirmClientId": "Client ID",
  "AddTenantConfirmClientSecret": "Client Secret",
  "AddTenantNextButtonText": "Next",
  "AddTenantCreateTenantButtonText": "Create Tenant",
  "LogFilterTimeRange": "Time Range",
  "LogFilterTimeRanges": [
    "Last 30 minutes",
    "Last hour",
    "Last 4 hours",
    "Last 12 hours",
    "Last 24 hours",
    "Last 48 hours",
    "Last 3 days",
    "Last 7 days"
  ],
  "LogFilterNoFilterKeyword": "All",
  "LogFilterObjectNameMissingErrorMesssage": "Please enter a name. Type 'All' if you do not want to filter object names.",
  "LogFilterActorNameMissingErrorMesssage": "Please enter a name. Type 'All' if you do not want to filter object names.",
  "LogFilterActorIdMissingErrorMesssage": "Please enter a name. Type 'All' if you do not want to filter object names.",
  "LogFilterDepartmentNameMissingErrorMesssage": "Please enter a name. Type 'All' if you do not want to filter object names.",
  "LogFilterOrganizationActionNames": [
    "All",
    "#UserSingular# Added",
    "#UserSingular# Deleted",
    "Resource Added",
    "Resource Deleted",
    "Vm Started",
    "Vm Restarted",
    "Vm Stopped",
    "Job Added",
    "Job Deleted",
    "#DepartmentSingular# Added",
    "#DepartmentSingular# Deleted",
    "#DepartmentSingular# Renamed",
    "Tenant Added",
    "Tenant Deleted",
    "#AdminSingular# Added",
    "#AdminSingular# Deleted"
  ],
  "LogFilterOrganizationObjectTypes": [
    "All",
    "Virtual Machine",
    "#UserSingular#",
    "#DepartmentSingular#",
    "Job",
    "#AdminSingular#",
    "Tenant"
  ],
  "LogFilterDepartmentActionNames": [
    "All",
    "#UserSingular# Added",
    "#UserSingular# Deleted",
    "Resource Added",
    "Resource Deleted",
    "Vm Started",
    "Vm Restarted",
    "Vm Stopped"
  ],
  "LogFilterDepartmentObjectTypes": [
    "All",
    "Virtual Machine",
    "#UserSingular#"
  ],
  "UserDashboardTitle": "My workspace",
  "UserDashboardTitleExtern": "Extern",
  "UserDashboardTitleWeblinks": "Weblinks",
  "UserDashboardTitleM365": "M365",
  "UserDashboardTitleFavorite": "Favorites",
  "ResetPasswordTitle": "Reset Password",
  "ResetPasswordMember": "Member",
  "ResetPasswordEnterPassword": "Enter Password",
  "ResetPasswordConfirmPassword": "Confirm Password",
  "ResetPasswordConfirmPasswordLabel": "Confirm Password",
  "ResetPasswordAutoGeneratedPassword": "Auto-Generated Password",
  "ResetPasswordNewPasswordInformationText": "Here you can change the password of a student. The member field is already filled and does not need to be edited. Enter the new password of the student in the new password field. Important: The password must have at least 8 characters. Repeat the password in the confirm password field. Confirm with reset password. The password can also be generated. To do this select Auto-generate Password and confirm with reset password.",
  "ResetPasswordCopyToClipboard": "Password copied to Clipboard!",
  "ResetPasswordResponse": "Password for User:",
  "ResetPasswordSuccessResponse": "has been reset successfully.",
  "ResetPasswordNewPwResponse": "New Password:",
  "ResetPasswordTempPwResponse": "Temporary Password is:",
  "ResetPasswordErrorResponse": "has not been reset.",
  "InputEMailPlaceholder": "E-Mail",
  "InputErrorInvalidEMail": "Please enter a valid E-Mail!",
  "CostControlApiKeyNotAvailable": "API Key not available",
  "CostControlAddApiKeyButtonText": "Add API Key",
  "CostControlTotalBudget": "Total Budget",
  "CostControlMonthlyLimit": "Monthly Limit",
  "CostControlThreshold": "Threshold",
  "CostControlAvailableBudget": "Remaining Budget",
  "CostControlCurrentBalance": "Current Balance",
  "CostControlUserRolesAllowedToEditMonthlyLimit": "#UserSingular#roles allowed to edit monthly limit",
  "CostControlUserRolesAllowedToEditApiKeys": "#UserSingular#roles allowed to edit API keys",
  "CostControlUserRolesAllowedToEditAiModels": "#UserSingular#roles allowed to edit AI models",
  "CostControlIsUserAllowedToManageApiKeys": "Is #UserPlural# allowed to manage API keys",
  "CostControlUserMonthlyLimitDefault": "Default monthly limit for #UserPlural#",
  "CostControlCopyApiKeyWarning": "Make sure to copy your api key now as you will not be able to see this again",
  "CostControlDeleteApiKeyConfirmationHeader": "Delete API Key",
  "CostControlDeleteApiKeyConfirmationMessage": "Do you really want to delete the API key",
  "CostControlActiveUserDepartment": "Booking cost on this #DepartmentSingular# active",
  "CostControlSelectAiModelsHeader": "Select AI Models",
  "CoDaPDFExportTitle": "Cost Overview",
  "CoDaPDFExportDateRange": "Date Range",
  "CoDaPDFExportCostPositionTableHeader": "Position",
  "CoDaPDFExportCostHeader": "Cost",
  "CoDaPDFExportTotalCostPosition": "Total Cost",
  "CoDaCostTrendsTabTitle": "Cost Trends",
  "CoDaDepartmentTrendsTabTitle": "Department Trends",
  "CoDaUserAnalysisTabTitle": "User Analysis",
  "CoDaDepartmentAnalysisTabTitle": "Department Analysis",
  "CoDaSimpleInfoPanelTotalCostHeader": "Total costs",
  "CoDaSimpleInfoPanelActiveUsersHeader": "Active users",
  "CoDaSimpleInfoPanelCostPerUserHeader": "Avg. Cost / User",
  "CoDaInfoCardUserInformationHeader": "User Information",
  "CoDaInfoCardDepartmentInformationHeader": "Department Information",
  "CoDaInfoCardDepartmentsCategory": "Departments",
  "CoDaInfoCardAIModelCategory": "AI Models",
  "CoDaConsumptionRequestsHeader": "Consumption & Requests",
  "CoDaConsumptionRequestsConsumption": "Consumption",
  "CoDaConsumptionRequestsRequests": "Requests",
  "CoDaCostTrendsTitle": "Cost Trends",
  "CoDaCostTrendsSubtitle": "Cost trends over time",
  "CoDaCostTrendsDataLabel": "Costs in",
  "CoDaDepartmentAnalysisTitle": "Department Analysis",
  "CoDaDepartmentAnalysisSubtitle": "Analyze individual department data",
  "CoDaDepartmentAnalysisDataLabel": "Requests",
  "CoDaUserAnalysisTitle": "User Analysis",
  "CoDaUserAnalysisSubtitle": "Analyze individual user data",
  "CoDaUserAnalysisDataLabel": "Requests",
  "CoDaDepartmentCostDistributionTitle": "Department cost distribution",
  "CoDaDepartmentCostDistributionSubtitle": "Department costs sorted by total cost",
  "CoDaDepartmentCostDistributionDatasetName": "Total cost per department",
  "CoDaDepartmentDetailTableTitle": "Department Details",
  "CoDaDepartmentDetailTableSubtitle": "Detailed metrics for each department",
  "CoDaDepartmentDetailTableDepartmentHeader": "Department",
  "CoDaDepartmentDetailTableCostHeader": "Cost",
  "CoDaDepartmentDetailTableRequestsHeader": "Requests",
  "CoDaModelUsageHeader": "Analysis of Top AI Model Usage",
  "CoDaModelUsageUserButton": "User",
  "CoDaModelUsageAiModelButton": "Ai Models",
  "TableHeaderName": "Name",
  "TableHeaderDescription": "Description",
  "TableHeaderLastAccessed": "Last Accessed",
  "TableHeaderInvitedAt": "Invited At",
  "TableHeaderType": "Type",
  "TableHeaderAction": "Action",
  "TableHeaderTimestamp": "Timestamp",
  "TableHeaderDepartmentName": "Department Name",
  "TableHeaderActionName": "Action Name",
  "TableHeaderObjectName": "Object Name",
  "TableHeaderObjectType": "Object Type",
  "TableHeaderActorName": "Actor Name",
  "TableHeaderActorId": "Actor ID",
  "TableHeaderLastLogin": "Last Login",
  "TableHeaderAlerts": "Alerts",
  "TableHeaderActions": "Actions",
  "TableHeaderStatus": "Status",
  "TableHeaderRoles": "Roles",
  "TableFooterItemsPerPage": "Items per page",
  "TableHeaderCreatedAt": "Created At",
  "TableHeaderApiKey": "API Key",
  "ButtonTextCreate": "Create",
  "ButtonTextCancel": "Cancel",
  "ButtonTextClose": "Close",
  "ButtonTextInvite": "Invite",
  "ButtonTextUpdate": "Update",
  "ButtonTextDelete": "Delete",
  "ButtonTextAdd": "Add",
  "ButtonTextApplyFilter": "Apply Filter",
  "ButtonTextResetPassword": "Reset Password",
  "ButtonTextBack": "Back",
  "ButtonTextDisabledInvite": "User already invited",
  "ErrorTextUserNotFound": "No User found",
  "ButtonTextDisabledInviteExtern": "Extern User",
  "ActionResponseSuccessStatus": "Success",
  "ActionResponseErrorStatus": "Error",
  "ActionResponseStatus": "Status",
  "DeletePopUpHeader": "to delete",
  "DeletePopUpTextStart": "Do you really want to remove the selected",
  "DeletePopUpTextEnd": "?",
  "UpdatePopUpHeader": "to update",
  "UpdatePopUpTextStart": "Do you really want to update the selected",
  "UpdatePopUpTextEnd": "?",
  "EditEmailContent": "Here you can edit the email header and the content of the invitation email for users and admin. You can use the following tags with ${tag}: [userName, targetOrganization, targetDepartment]",
  "Error": "Error:",
  "ErrorTextBackend": "The backend encountered an Error! Please contact support!",
  "ErrorTextMissingPermissions": "You lack the neccessary permissions!",
  "ErrorTextTenantID": "Unable to acquire TenantId! Please contact an Administrator!",
  "ErrorTextUnknownDepartment": "could not be found",
  "ErrorTextUnknownUser": "Unable to find User!",
  "ErrorTextUnknownUserID": "Unable to find User with ID:",
  "ErrorTextUnableToProcessRequest": "Unable to process Request!",
  "ErrorTextRequired": "Please enter a value",
  "ErrorTextNameRequired": "Please enter a valid name",
  "ErrorTextMinLength": "At least 8 characters required",
  "ErrorTextMaxNameLength": "Maximum length: 20 characters",
  "ErrorTextMaxDescriptionLength": "Maximum length: 255 characters",
  "ErrorTextUnsuppotedCharactersInName": "Only alphanumeric characters, underscores, and dashes are allowed",
  "ErrorTextUnsuppotedCharactersInNameShort": "Characters: 0-9 a-z A-Z _ -",
  "ErrorTextPasswordsDoNotMatch": "The password does not match",
  "ErrorTextEnterNewPasswordFirst": "Please enter a new password first",
  "ErrorTextNoConsumptionData": "No consumption data is currently available",
  "ErrorTextDescriptionRequired": "Please enter a valid description",
  "CostManagementTimeRange": "Time Range",
  "CostManagementTotal": "Total",
  "CostManagementTokens": "Tokens",
  "CostManagementTokensTotal": "Total",
  "CostManagementTokensRelative": "Relative",
  "CostManagementModels": "Models",
  "CostManagementModel": "Model",
  "CostManagementFilter": "Filter",
  "CostManagementSelectFilter": "Select Filter",
  "CostManagementSelectValue": "Select Value",
  "CostManagementFilterApply": "Apply Filter",
  "CostManagementExport": "Export",
  "CostManagementRequests": "Requests",
  "CostManagementRequest": "Request",
  "CostManagementResponse": "Response",
  "CostManagementRequestResponseCost": "Request/Response Cost",
  "CostManagementTime": "Time",
  "January": "January",
  "February": "February",
  "March": "March",
  "April": "April",
  "May": "May",
  "June": "June",
  "July": "July",
  "August": "August",
  "September": "September",
  "October": "October",
  "November": "November",
  "December": "December",
  "MondayMin": "Mo",
  "TuesdayMin": "Tu",
  "WednesdayMin": "We",
  "ThursdayMin": "Th",
  "FridayMin": "Fr",
  "SaturdayMin": "Sa",
  "SundayMin": "Su",
  "Monday": "Monday",
  "Tuesday": "Tuesday",
  "Wednesday": "Wednesday",
  "Thursday": "Thursday",
  "Friday": "Friday",
  "Saturday": "Saturday",
  "Sunday": "Sunday",
  "DaysArray": [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ],
  "WeeksNumbered": [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth"
  ],
  "VmMetainformationMetainformation": "Metainformation",
  "VmMetainformationVm": "VM",
  "VmMetainformationStatus": "Status:",
  "VmMetainformationAdminUsername": "Admin Username:",
  "VmMetainformationHardware": "Hardware",
  "VmMetainformationVCpuCores": "vCPU Cores:",
  "VmMetainformationRam": "RAM:",
  "VmMetainformationDataDiskSize": "Data Disk Size:",
  "VmMetainformationDataDisks": "Data Disks:",
  "VmMetainformationOsDiskSize": "OS Disk Size:",
  "VmMetainformationVmSize": "VM Size:",
  "VmMetainformationSoftware": "Software",
  "VmMetainformationOperatingSystem": "Operating System:",
  "VmMetainformationOsVersion": "OS Version:",
  "VmMetainformationAzure": "Azure",
  "VmMetainformationRegion": "Region:",
  "VmMetainformationResourceGroup": "Resource Group:",
  "VmAlertsStopping": "Please wait while the VM is stopping",
  "VmAlertsDeallocating": "Please wait while the VM is deallocating",
  "VmAlertsStarting": "Please wait while the VM is starting",
  "VmAlertsStopped": "VM generates cost!",
  "SearchPlaceholder": "Search",
  "NavBarCollapse": "Collapse",
  "InvitationIndicatorUsername": "Invitation in progress...",
  "InvitationFailedIndicatorUsername": "Invitation failed...",
  "TemplateSpecsAddTemplateButtonText": "Add Template Sepc",
  "TemplateSpecDescription": "Description:",
  "TemplateSpecResourceType": "Ressource-Type:",
  "TemplateSpecInformationCardTitle": "Information",
  "TemplateSpecTableHeaderVersion": "Versions",
  "DeleteTemplateSpecHeader": "Template Spec delete",
  "ConfigEmailHeaderLabel": "Email Header",
  "ConfigUserTypeLabel": "User Type",
  "ToastSuccessTitle": "Success",
  "ToastErrorTitle": "Error",
  "ToastInformationTitle": "Information",
  "ToastWarningTitle": "Warning",
  "ToastAdminInviteSuccessTitle": "#AdminSingular# invite successful",
  "ToastAdminInviteErrorTitle": "#AdminSingular# invite failed",
  "ToastAdminDeleteSuccessTitle": "#AdminSingular# delete successful",
  "ToastAdminDeleteErrorTitle": "#AdminSingular# delete failed",
  "ToastUserInviteSuccessTitle": "#UserSingular# invite successful",
  "ToastUserInviteErrorTitle": "#UserSingular# invite failed",
  "ToastUserUpdateSuccessTitle": "#UserSingular# update successful",
  "ToastUserUpdateErrorTitle": "#UserSingular# update failed",
  "ToastUserDeleteSuccessTitle": "#UserSingular# delete successful",
  "ToastUserDeleteErrorTitle": "#UserSingular# delete failed",
  "ToastResourceAddSuccessTitle": "Resource added successfully",
  "ToastResourceAddErrorTitle": "Failed to add resource",
  "ToastJobAddSuccessTitle": "Job added successfully",
  "ToastJobAddErrorTitle": "Failed to add job",
  "ToastJobUpdateSuccessTitle": "Job updated successfully",
  "ToastJobUpdateErrorTitle": "Failed to update job",
  "ToastJobDeleteSuccessTitle": "Job deleted successfully",
  "ToastJobDeleteErrorTitle": "Failed to delete job",
  "ToastDepartmentCreateSuccessTitle": "#DepartmentSingular# created successfully",
  "ToastDepartmentCreateErrorTitle": "Failed to create #DepartmentSingular#",
  "ToastDepartmentDescriptionUpdateSuccessTitle": "#DepartmentSingular# description updated successfully",
  "ToastDepartmentDescriptionUpdateErrorTitle": "Failed to update #DepartmentSingular# description",
  "ToastDepartmentDeleteSuccessTitle": "#DepartmentSingular# deleted successfully",
  "ToastDepartmentDeleteErrorTitle": "Failed to delete #DepartmentSingular#",
  "ToastConfigUpdateSuccessTitle": "Configuration updated successfully",
  "ToastConfigUpdateErrorTitle": "Failed to update configuration",
  "ToastPrivateGptUpdateSuccessTitle": "Private GPT onfiguration updated successfully",
  "ToastPrivateGptUpdateErrorTitle": "Failed to update private GPT",
  "ToastPrivateGptCostExportSuccessTitle": "Private GPT cost export successful",
  "ToastPrivateGptCostExportErrorTitle": "Failed to export private GPT cost",
  "ToastPrivateGptPatCopySuccessTitle": "PAT copied successfully",
  "ToastCostControlApiKeyAddSuccessTitle": "API key created successfully",
  "ToastCostControlApiKeyAddErrorTitle": "Failed to create API key",
  "ToastCostControlApiKeyDeleteSuccessTitle": "API key deleted successfully",
  "ToastCostControlApiKeyDeleteErrorTitle": "Failed to delete API key",
  "ToastCostControlApiKeyCopySuccessTitle": "API key copied",
  "ToastCostControlCostManagementDataUpdateSuccessTitle": "Cost management data updated successfully",
  "ToastCostControlCostManagementDataUpdateErrorTitle": "Failed to update cost management data",
  "ToastLogFilterApplySuccessTitle": "Filter applied successfully",
  "ToastLogFilterApplyErrorTitle": "Filter could not be applied",
  "ToastLogFilterApplyNoDataTitle": "No data matching the filter",
  "ToastVmRdpFileUnavailableTitle": "RDP file unavailable",
  "ToastVmStartingTitle": "VM is starting",
  "ToastVmStoppingTitle": "VM is stopping",
  "ToastVmRestartingTitle": "VM is restarting",
  "PrimeNgFirstPage": "First Page",
  "PrimeNgLastPage": "Last Page",
  "PrimeNgNextPage": "Next Page",
  "PrimeNgPreviousPage": "Previous Page",
  "PrimeNgRowsPerPage": "Items per page",
  "PrimeNgStartsWith": "Starts with",
  "PrimeNgContains": "Contains",
  "PrimeNgNotContains": "Not contains",
  "PrimeNgEndsWith": "Ends with",
  "PrimeNgEquals": "Equals",
  "PrimeNgNotEquals": "Not equals",
  "PrimeNgNoFilter": "No Filter",
  "PrimeNgLt": "Less than",
  "PrimeNgLte": "Less than or equal to",
  "PrimeNgGt": "Greater than",
  "PrimeNgGte": "Greater than or equal to",
  "PrimeNgIs": "Is",
  "PrimeNgIsNot": "Is not",
  "PrimeNgBefore": "Before",
  "PrimeNgAfter": "After",
  "PrimeNgDateIs": "Date is",
  "PrimeNgDateIsNot": "Date is not",
  "PrimeNgDateBefore": "Date before",
  "PrimeNgDateAfter": "Date after",
  "PrimeNgClear": "Clear",
  "PrimeNgApply": "Apply",
  "PrimeNgMatchAll": "Match All",
  "PrimeNgMatchAny": "Match Any",
  "PrimeNgAddRule": "Add Rule",
  "PrimeNgRemoveRule": "Remove Rule",
  "PrimeNgAccept": "Accept",
  "PrimeNgReject": "Reject",
  "PrimeNgChoose": "Choose",
  "PrimeNgUpload": "Upload",
  "PrimeNgCancel": "Cancel",
  "PrimeNgFileSizeTypes": [
    "Bytes",
    "KB",
    "MB",
    "GB"
  ],
  "PrimeNgDayNames": [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  "PrimeNgDayNamesShort": [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat"
  ],
  "PrimeNgDayNamesMin": [
    "Su",
    "Mo",
    "Tu",
    "We",
    "Th",
    "Fr",
    "Sa"
  ],
  "PrimeNgMonthNames": [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  "PrimeNgMonthNamesShort": [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  "PrimeNgDateFormat": "mm/dd/yy",
  "PrimeNgFirstDayOfWeek": 0,
  "PrimeNgToday": "Today",
  "PrimeNgWeekHeader": "Wk",
  "PrimeNgWeak": "Weak",
  "PrimeNgMedium": "Medium",
  "PrimeNgStrong": "Strong",
  "PrimeNgPasswordPrompt": "Enter a password",
  "PrimeNgEmptyMessage": "No results found",
  "PrimeNgEmptyFilterMessage": "No results found",
  "PrimeNgPending": "Pending",
  "PrimeNgChooseYear": "Choose a year",
  "PrimeNgChooseMonth": "Choose a month",
  "PrimeNgChooseDate": "Choose a date",
  "PrimeNgPrevDecade": "Previous Decade",
  "PrimeNgNextDecade": "Next Decade",
  "PrimeNgPrevYear": "Previous Year",
  "PrimeNgNextYear": "Next Year",
  "PrimeNgPrevMonth": "Previous Month",
  "PrimeNgNextMonth": "Next Month",
  "PrimeNgPrevHour": "Previous Hour",
  "PrimeNgNextHour": "Next Hour",
  "PrimeNgPrevMinute": "Previous Minute",
  "PrimeNgNextMinute": "Next Minute",
  "PrimeNgPrevSecond": "Previous Second",
  "PrimeNgNextSecond": "Next Second",
  "PrimeNgAm": "AM",
  "PrimeNgPm": "PM",
  "PrimeNgSearchMessage": "{0} results are available",
  "PrimeNgSelectionMessage": "{0} items selected",
  "PrimeNgEmptySelectionMessage": "No selected item",
  "PrimeNgEmptySearchMessage": "No results found",
  "PrimeNgAria": {
    "trueLabel": "True",
    "falseLabel": "False",
    "nullLabel": "Not Selected",
    "star": "1 star",
    "stars": "{0} stars",
    "selectAll": "Select All",
    "unselectAll": "Unselect All",
    "close": "Close",
    "previous": "Previous",
    "next": "Next",
    "navigation": "Navigation",
    "scrollTop": "Scroll Top",
    "moveTop": "Move Top",
    "moveUp": "Move Up",
    "moveDown": "Move Down",
    "moveBottom": "Move Bottom",
    "moveToTarget": "Move to Target",
    "moveToSource": "Move to Source",
    "moveAllToTarget": "Move All to Target",
    "moveAllToSource": "Move All to Source",
    "pageLabel": "Page",
    "firstPageLabel": "First Page",
    "lastPageLabel": "Last Page",
    "nextPageLabel": "Next Page",
    "prevPageLabel": "Previous Page",
    "rowsPerPageLabel": "Rows per page",
    "previousPageLabel": "Previous Page",
    "jumpToPageDropdownLabel": "Jump to page dropdown",
    "jumpToPageInputLabel": "Jump to page input",
    "selectRow": "Select Row",
    "unselectRow": "Unselect Row",
    "expandRow": "Expand Row",
    "collapseRow": "Collapse Row",
    "showFilterMenu": "Show Filter Menu",
    "hideFilterMenu": "Hide Filter Menu",
    "filterOperator": "Filter Operator",
    "filterConstraint": "Filter Constraint",
    "editRow": "Edit Row",
    "saveEdit": "Save Edit",
    "cancelEdit": "Cancel Edit",
    "listView": "List View",
    "gridView": "Grid View",
    "slide": "Slide",
    "slideNumber": "Slide {0}",
    "zoomImage": "Zoom Image",
    "zoomIn": "Zoom In",
    "zoomOut": "Zoom Out",
    "rotateRight": "Rotate Right",
    "rotateLeft": "Rotate Left",
    "listLabel": "List",
    "selectColor": "Select Color",
    "removeLabel": "Remove",
    "browseFiles": "Browse Files",
    "maximizeLabel": "Maximize"
  }
}
