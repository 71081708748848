import { ConfigDTO } from 'app/core/api/models/configDTO.model';

export const DefaultConfig: ConfigDTO = {
  languageAbbreviation: 'de',
  organizationId: '',
  licenseKey: '',
  licenseEdition: '',
  lastChanged: null,

  isUserPortalEnabled: false,
  isDepartmentsDynamic: false,
  isCustomRoleEnabled: false,
  isResourcesEnabled: false,
  isCronJobsEnabled: false,
  isMonitoringEnabled: false,
  isTemplateSpecsEnabled: false,
  isPrivateGptEnabled: false,
  isCostControlApiEnabled: false,
  isCostControlUiEnabled: false,
  isUserEmailNotificationOnInviteEnabled: false,
  isAdminEmailNotificationOnInviteEnabled: false,
  isUserPortalCommunityTileEnabled: false,
  isAdminPortalCommunityTileEnabled: false,

  userPortalDepartmentNameSingular: 'Department',
  userPortalDepartmentNamePlural: 'Departments',
  userPortalUserNameSingular: 'User',
  userPortalUserNamePlural: 'Users',
  adminPortalDepartmentNameSingular: 'Department',
  adminPortalDepartmentNamePlural: 'Departments',
  adminPortalUserNameSingular: 'User',
  adminPortalUserNamePlural: 'Users',
  emailSubjectLine: 'white duck GmbH - Cloud Control invitation',

  learningMaterialCardsConfigs: [],
  externCardsConfigs: [],
  favoriteCardsConfigs: [],

  lightTheme: 'blue',
  darkTheme: 'blue',

  costControlAvailableAiModels: new Map<string, string[]>(),
  availableUserRoles: [],
};
