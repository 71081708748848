import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigDTO } from '../api/models/configDTO.model';
import { UpdateConfigRequestDTO } from 'app/core/api/models/updateConfigRequestDTO.model';
import { DashBoardCardConfigDTO } from '@shared/models/dashboardCardConfigDTO';
import { UpdateInviteEmailDTO } from '../api/models/updateInviteEmailDTO.model';
import { type AvailableThemes } from '@shared/services/theme/types/available-themes.type';
import { ConfigApiService } from '../api/services/config-api.service';
import { DefaultConfig } from './constants/default-config.constant';
import { type FeatureFlag } from '../feature-flags/types/feature-flag.type';
import { FEATURE_FLAGS } from '../feature-flags/constants/feature-flags.constant';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly CONFIG_STORAGE_KEY = 'user-config';

  private defaultOrganizationConfig: ConfigDTO = DefaultConfig;
  private enabledFeatureFlags: FeatureFlag[] = [];
  private organizationConfig: ConfigDTO = null;

  private readonly config$: BehaviorSubject<ConfigDTO> = new BehaviorSubject<ConfigDTO>(null);

  constructor(private readonly configApiService: ConfigApiService) {}

  /**
   * Get the organization config.
   * If the organization config has already been loaded, the internal copy is returned.
   * If the internal copy is not available, the organization config is loaded from the local storage.
   * Finally if the organization config is not available in the local storage, it is loaded from the API.
   *
   * @returns An observable for the organization config
   */
  getOrganizationConfig(): Observable<ConfigDTO> {
    let currentConfig = this.retrieveCurrentConfig();

    if (currentConfig === null) {
      // Organization config has not been loaded yet
      this.loadConfiguration();
    } else {
      // Check if a new config version is available
      this.configApiService
        .checkForNewConfigVersion(currentConfig.lastChanged)
        .subscribe((newConfigAvailabel: boolean) => {
          if (newConfigAvailabel) {
            this.loadConfiguration();
          } else {
            this.organizationConfig = currentConfig;
            this.loadEnabledFeatureFlags();
            this.config$.next(currentConfig);
          }
        });
    }

    return this.config$;
  }

  /**
   * Update the organization config.
   * The organization config is updated via the API and the internal copy is updated.
   *
   * @param updateConfigRequest The update config request
   * @returns An observable for the updated organization config
   */
  updateOrganizationConfig(updateConfigRequest: UpdateConfigRequestDTO): Observable<ConfigDTO> {
    this.config$.next(this.defaultOrganizationConfig);

    this.configApiService.updateOrganizationConfig(updateConfigRequest).subscribe((config: ConfigDTO) => {
      localStorage.setItem(this.CONFIG_STORAGE_KEY, JSON.stringify(config));
      this.organizationConfig = config;
      this.config$.next(config);
    });

    return this.config$;
  }

  /**
   * Get all enabled feature flags of this organization.
   *
   * @returns An array containing only the enabled feature flags
   */
  getAllEnabledFeatureFlags(): FeatureFlag[] {
    return this.enabledFeatureFlags;
  }

  isOrganizationConfigAvailable(): boolean {
    return this.organizationConfig !== null || localStorage.getItem(this.CONFIG_STORAGE_KEY) !== null;
  }

  // Getters
  getLanguageAbbreviation(): string {
    return localStorage.getItem('ClientLanguage') ?? this.organizationConfig?.languageAbbreviation ?? 'de';
  }

  getOrganizationId(): string {
    return this.organizationConfig?.organizationId ?? this.defaultOrganizationConfig.organizationId;
  }

  getLicenseKey(): string {
    return this.organizationConfig?.licenseKey ?? this.defaultOrganizationConfig.licenseKey;
  }

  getLicenseEdition(): string {
    return this.organizationConfig?.licenseEdition ?? this.defaultOrganizationConfig.licenseEdition;
  }

  getLoadUserGroups(): boolean {
    return this.isDepartmentsDynamic();
  }

  getAvailableUserRoles(): string[] {
    return this.organizationConfig?.availableUserRoles ?? this.defaultOrganizationConfig.availableUserRoles;
  }

  // Feature Flags
  //////////////////////////////////////////////////////////////////////////
  isDepartmentsDynamic(): boolean {
    return this.organizationConfig?.isDepartmentsDynamic ?? this.defaultOrganizationConfig.isDepartmentsDynamic;
  }

  isUserPortalEnabled(): boolean {
    return this.organizationConfig?.isUserPortalEnabled ?? this.defaultOrganizationConfig.isUserPortalEnabled;
  }

  isCustomRoleEnabled(): boolean {
    return this.organizationConfig?.isCustomRoleEnabled ?? this.defaultOrganizationConfig.isCustomRoleEnabled;
  }

  isResourcesEnabled(): boolean {
    return this.organizationConfig?.isResourcesEnabled ?? this.defaultOrganizationConfig.isResourcesEnabled;
  }

  isCronJobsEnabled(): boolean {
    return this.organizationConfig?.isCronJobsEnabled ?? this.defaultOrganizationConfig.isCronJobsEnabled;
  }

  isMonitoringEnabled(): boolean {
    return this.organizationConfig?.isMonitoringEnabled ?? this.defaultOrganizationConfig.isMonitoringEnabled;
  }

  isTemplateSpecsEnabled(): boolean {
    return this.organizationConfig?.isTemplateSpecsEnabled ?? this.defaultOrganizationConfig.isTemplateSpecsEnabled;
  }

  isPrivateGptEnabled(): boolean {
    return this.organizationConfig?.isPrivateGptEnabled ?? this.defaultOrganizationConfig.isPrivateGptEnabled;
  }

  isCostControlApiEnabled(): boolean {
    return this.organizationConfig?.isCostControlApiEnabled ?? this.defaultOrganizationConfig.isCostControlApiEnabled;
  }

  isCostControlUiEnabled(): boolean {
    return this.organizationConfig?.isCostControlUiEnabled ?? this.defaultOrganizationConfig.isCostControlUiEnabled;
  }

  isUserEmailNotificationOnInviteEnabled(): boolean {
    return (
      this.organizationConfig?.isUserEmailNotificationOnInviteEnabled ??
      this.defaultOrganizationConfig.isUserEmailNotificationOnInviteEnabled
    );
  }

  isAdminEmailNotificationOnInviteEnabled(): boolean {
    return (
      this.organizationConfig?.isAdminEmailNotificationOnInviteEnabled ??
      this.defaultOrganizationConfig.isAdminEmailNotificationOnInviteEnabled
    );
  }

  isUserPortalCommunityTileEnabled(): boolean {
    return (
      this.organizationConfig?.isUserPortalCommunityTileEnabled ??
      this.defaultOrganizationConfig.isUserPortalCommunityTileEnabled
    );
  }

  isAdminPortalCommunityTileEnabled(): boolean {
    return (
      this.organizationConfig?.isAdminPortalCommunityTileEnabled ??
      this.defaultOrganizationConfig.isAdminPortalCommunityTileEnabled
    );
  }

  // Configurable properties
  getUserPortalDepartmentNameSingular(): string {
    return (
      this.organizationConfig?.userPortalDepartmentNameSingular ??
      this.defaultOrganizationConfig.userPortalDepartmentNameSingular
    );
  }

  getUserPortalDepartmentNamePlural(): string {
    return (
      this.organizationConfig?.userPortalDepartmentNamePlural ??
      this.defaultOrganizationConfig.userPortalDepartmentNamePlural
    );
  }

  getUserPortalUserNameSingular(): string {
    return (
      this.organizationConfig?.userPortalUserNameSingular ?? this.defaultOrganizationConfig.userPortalUserNameSingular
    );
  }

  getUserPortalUserNamePlural(): string {
    return this.organizationConfig?.userPortalUserNamePlural ?? this.defaultOrganizationConfig.userPortalUserNamePlural;
  }

  getAdminPortalDepartmentNameSingular(): string {
    return (
      this.organizationConfig?.adminPortalDepartmentNameSingular ??
      this.defaultOrganizationConfig.adminPortalDepartmentNameSingular
    );
  }

  getAdminPortalDepartmentNamePlural(): string {
    return (
      this.organizationConfig?.adminPortalDepartmentNamePlural ??
      this.defaultOrganizationConfig.adminPortalDepartmentNamePlural
    );
  }

  getAdminPortalUserNameSingular(): string {
    return (
      this.organizationConfig?.adminPortalUserNameSingular ?? this.defaultOrganizationConfig.adminPortalUserNameSingular
    );
  }

  getAdminPortalUserNamePlural(): string {
    return (
      this.organizationConfig?.adminPortalUserNamePlural ?? this.defaultOrganizationConfig.adminPortalUserNamePlural
    );
  }

  // Cards
  getLearningCardConfigs(): DashBoardCardConfigDTO[] {
    return (
      this.organizationConfig?.learningMaterialCardsConfigs ??
      this.defaultOrganizationConfig.learningMaterialCardsConfigs
    );
  }

  getExternCardConfigs(): DashBoardCardConfigDTO[] {
    return this.organizationConfig?.externCardsConfigs ?? this.defaultOrganizationConfig.externCardsConfigs;
  }

  getFavoriteCardConfigs(): DashBoardCardConfigDTO[] {
    return this.organizationConfig?.favoriteCardsConfigs ?? this.defaultOrganizationConfig.favoriteCardsConfigs;
  }

  // Invite E-Mail Content
  //////////////////////////////////////////////////////////////////////////
  getEmailSubjectLine(): string {
    return this.organizationConfig?.emailSubjectLine ?? this.defaultOrganizationConfig.emailSubjectLine;
  }

  getUserInviteEmailContent(): Observable<UpdateInviteEmailDTO> {
    return this.configApiService.getUserInviteEmailContent();
  }

  updateUserInviteEmailContent(inviteEmailContent: UpdateInviteEmailDTO): Observable<void> {
    return this.configApiService.updateUserInviteEmailContent(inviteEmailContent);
  }

  getAdminInviteEmailContent(): Observable<UpdateInviteEmailDTO> {
    return this.configApiService.getAdminInviteEmailContent();
  }

  updateAdminInviteEmailContent(inviteEmailContent: UpdateInviteEmailDTO): Observable<void> {
    return this.configApiService.updateAdminInviteEmailContent(inviteEmailContent);
  }

  // Cost Control
  getCostControlAvailableAiModels(): Map<string, string[]> {
    return (
      this.organizationConfig?.costControlAvailableAiModels ??
      this.defaultOrganizationConfig.costControlAvailableAiModels
    );
  }

  // Themes
  getLightTheme(): AvailableThemes {
    return (this.organizationConfig?.lightTheme ?? this.defaultOrganizationConfig.lightTheme) as AvailableThemes;
  }

  getDarkTheme(): AvailableThemes {
    return (this.organizationConfig?.darkTheme ?? this.defaultOrganizationConfig.darkTheme) as AvailableThemes;
  }

  // Private methods
  /**
   * Loads all enabled feature flags of this organization into an array for easier access.
   * This array is mainly used for the view model services.
   */
  private loadEnabledFeatureFlags(): void {
    const featureFlags: FeatureFlag[] = [];

    if (this.isUserPortalEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_USER_PORTAL_ENABLED);
    }

    if (this.isDepartmentsDynamic()) {
      featureFlags.push(FEATURE_FLAGS.IS_DEPARTMENTS_DYNAMIC);
    }

    if (this.isCustomRoleEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_CUSTOM_ROLE_ENABLED);
    }

    if (this.isResourcesEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_RESOURCES_ENABLED);
    }

    if (this.isCronJobsEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_CRON_JOBS_ENABLED);
    }

    if (this.isMonitoringEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_MONITORING_ENABLED);
    }

    if (this.isTemplateSpecsEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_TEMPLATE_SPECS_ENABLED);
    }

    if (this.isPrivateGptEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_PRIVATE_GPT_ENABLED);
    }

    if (this.isCostControlApiEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_COST_CONTROL_API_ENABLED);
    }

    if (this.isCostControlUiEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_COST_CONTROL_UI_ENABLED);
    }

    if (this.isUserEmailNotificationOnInviteEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_USER_EMAIL_NOTIFICATION_ON_INVITE_ENABLED);
    }

    if (this.isAdminEmailNotificationOnInviteEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_ADMIN_EMAIL_NOTIFICATION_ON_INVITE_ENABLED);
    }

    if (this.isUserPortalCommunityTileEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_USER_PORTAL_COMMUNITY_TILE_ENABLED);
    }

    if (this.isAdminPortalCommunityTileEnabled()) {
      featureFlags.push(FEATURE_FLAGS.IS_ADMIN_PORTAL_COMMUNITY_TILE_ENABLED);
    }

    this.enabledFeatureFlags = featureFlags;
  }

  /**
   * Get the current organization config.
   * @returns The current organization config or NULL if it is not available.
   */
  private retrieveCurrentConfig(): ConfigDTO {
    let storedConfig: string = null;

    if (this.organizationConfig !== null) {
      // Organization config has already been loaded
      return this.organizationConfig;
    } else {
      // Check if the organization config has been stored in the local storage
      storedConfig = localStorage.getItem(this.CONFIG_STORAGE_KEY);
    }

    if (storedConfig !== null) {
      // Organization config has been stored in the local storage
      return JSON.parse(storedConfig);
    }

    return null;
  }

  /**
   * Load the organization config from the API and store it in the local storage.
   */
  private loadConfiguration(): void {
    this.configApiService.getOrganizationConfig().subscribe((config: ConfigDTO) => {
      this.organizationConfig = config;
      localStorage.removeItem(this.CONFIG_STORAGE_KEY);
      localStorage.setItem(this.CONFIG_STORAGE_KEY, JSON.stringify(config));
      this.loadEnabledFeatureFlags();
      this.config$.next(this.organizationConfig);
    });
  }
}
