import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ConfigDTO } from 'app/core/api/models/configDTO.model';
import { UpdateConfigRequestDTO } from 'app/core/api/models/updateConfigRequestDTO.model';
import { UpdateInviteEmailDTO } from '../models/updateInviteEmailDTO.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigApiService {
  private readonly baseUrl = environment.baseUrl;

  private readonly configControllerEndpoint = 'management/configuration';
  private readonly versionCheckEndpoint = this.configControllerEndpoint + '/checkforversion';
  private readonly userEmailEndpoint = this.configControllerEndpoint + '/userinviteemailcontent';
  private readonly adminEmailEndpoint = this.configControllerEndpoint + '/admininviteemailcontent';

  constructor(private readonly http: HttpClient) {}

  checkForNewConfigVersion(lastChanged: Date): Observable<boolean> {
    let lastChangedString = new Date(lastChanged).toISOString();

    return this.http.get<boolean>(this.baseUrl + this.versionCheckEndpoint + '?lastChanged=' + lastChangedString);
  }

  // General organization configuration
  getOrganizationConfig(): Observable<ConfigDTO> {
    return this.http.get<ConfigDTO>(this.baseUrl + this.configControllerEndpoint);
  }

  updateOrganizationConfig(updateConfigRequest: UpdateConfigRequestDTO): Observable<ConfigDTO> {
    return this.http.post<ConfigDTO>(this.baseUrl + this.configControllerEndpoint, updateConfigRequest);
  }

  // User invite E-Mail configuration
  getUserInviteEmailContent(): Observable<UpdateInviteEmailDTO> {
    return this.http.get<any>(this.baseUrl + this.userEmailEndpoint);
  }

  updateUserInviteEmailContent(inviteEmailContent: UpdateInviteEmailDTO): Observable<void> {
    return this.http.post<void>(this.baseUrl + this.userEmailEndpoint, inviteEmailContent);
  }

  // Admin invite E-Mail configuration
  getAdminInviteEmailContent(): Observable<UpdateInviteEmailDTO> {
    return this.http.get<UpdateInviteEmailDTO>(this.baseUrl + this.adminEmailEndpoint);
  }

  updateAdminInviteEmailContent(inviteEmailContent: UpdateInviteEmailDTO): Observable<void> {
    return this.http.post<void>(this.baseUrl + this.adminEmailEndpoint, inviteEmailContent);
  }
}
