{
  "UserMenuLogoutButtonText": "Abmelden",
  "InstanceAdminDashboardTitle": "Dashboard",
  "InstanceAdminOrganisationInfoPageTitle": "Organisation",
  "InstanceAdminOrganizationsTabTitle": "Organisationen",
  "InstanceAdminOrganizationOnboardingTabTitle": "Onboarding",
  "OnboardingLinkValidUntilText": "gültig bis:",
  "OnboardOrganzationButtonText": "Erstellen",
  "LinkCopiedToClipboardMassage": "Link wurde in die Zwischenablage kopiert!",
  "OrganizationInfoCardDepartments": "Departments:",
  "OrganizationInfoCardAdmins": "Administratoren:",
  "OrganizationInfoCardUsers": "Benutzer:",
  "OrganizationInfoCardResources": "Ressourcen:",
  "OrganizationInfoCardRoles": "Rollen:",
  "OrganizationInfoCardJobs": "CRON-Jobs:",
  "OrganizationOffBoardButton": "Offboard Organization",
  "OrganizationOffBoardDialogTitel": "Offboard Organization",
  "OrganizationOffBoardDialogMessage": "Removing an organization will lead to the deletion of all Departments, Admins, User, Resources and CRON-Jobs assoziated with this organization. This will also remove the App Resistration. This action cannot be undone.",
  "AdminDashboardTitle": "Home",
  "AdminDashboardDepartmentCardInfo": "Diese Seite bietet eine Übersicht aller existierenden #DepartmentPlural#.",
  "AdminDashboardSettingsCardInfo": "Auf dieser Seite lassen sich einige Einstellungen für die Organisation vornehmen. Unter anderem lassen sich Mandanten und Administratoren verwalten.",
  "AdminSettingsTitle": "Organisations-Einstellungen",
  "AdminSettingsNavSideBarTitle": "Einstellungen",
  "AdminSettingsTenantsTabTitle": "Mandanten",
  "AdminSettingsAddTenantButtonText": "Mandant verknüpfen",
  "AdminSettingsAdminsTabTitle": "Administratoren",
  "AdminSettingsAddAdminButtonText": "Administrator hinzufügen",
  "AdminSettingsJobsTabTitle": "Aufgaben",
  "AdminSettingsAddJobButtonText": "Aufgabe hinzufügen",
  "AdminSettingsLogsTitle": "Protokolle",
  "AdminSettingsLogsNoDataErrorText": "Keine Protokolleinträge gefunden",
  "AdminSettingsConfigTabTitle": "Konfiguration",
  "AdminSettingsConfigEMailSection": "Einladungs",
  "AdminSettingsAboutTitle": "Über",
  "AdminSettingsAboutProductInformation": "Produktinformation",
  "AdminSettingsAboutGenerel": "Allgemein",
  "DepartmentDetailResourcesTabTitle": "Ressourcen",
  "DepartmentDetailAddButtonText": "Hinzufügen",
  "DepartmentDetailDescriptionUpdateButtonText": "Beschreibung aktualisieren",
  "InviteSelectTenant": "Mandant",
  "InviteAdminHeader": "#AdminSingular# hinzufügen",
  "InviteUserHeader": "#UserSingular# hinzufügen",
  "InviteSuggestionsHeader": "Vorschläge",
  "InviteSelectionHeader": "Auswahl",
  "DepartmentCreateButton": "#DepartmentSingular# hinzufügen",
  "DepartmentCreateTitle": "#DepartmentSingular# erstellen",
  "DepartmentCreateDepartmentName": "Name",
  "DepartmentCreateDepartmentDescription": "Beschreibung",
  "AddResourceTitle": "Ressource hinzufügen",
  "AddResourceSelectTenantStepTitle": "Mandant auswählen",
  "AddResourceSelectSubscriptionStepTitle": "Abonnement auswählen",
  "AddResourceSelectResourceGroupStepTitle": "Ressourcengruppe auswählen",
  "AddResourceSelectResourceStepTitle": "Ressource auswählen",
  "AddResourceSelectConfirmationStepTitle": "Bestätigung",
  "AddResourceSelectTenantInfoText": "Mandant für die Ressource auswählen",
  "AddResourceSelectSubscriptionInfoText": "Abonnement für die Ressource auswählen",
  "AddResourceSelectResourceGroupInfoText": "Ressourcengruppe für die Ressource auswählen",
  "AddResourceConfirmationTenant": "Mandant",
  "AddResourceConfirmationSubscription": "Abonnement",
  "AddResourceConfirmationResourceGroup": "Ressourcengruppe",
  "AddResourceConfirmationResource": "Ressource",
  "AddResourceConfirmationAddResourceButtonText": "Ressource hinzufügen",
  "AddJobTitle": "Job erstellen",
  "EditJobTitle": "Job bearbeiten",
  "JobsDialogJobData": "Jobdaten",
  "JobsDialogJobName": "Jobname",
  "JobsDialogResourceType": "Ressourcentyp",
  "JobsDialogAction": "Aktion",
  "JobsDialogTiming": "Zeitplanung",
  "JobsDialogTimingMessage": "Bitte einen Zeitplan auswählen",
  "JobsDialogTimingDaily": "Täglich",
  "JobsDialogTimingDailyEvery": "Alle",
  "JobsDialogTimingWeekly": "Wöchentlich",
  "JobsDialogTimingMonthly": "Monatlich",
  "JobsDialogTimingEvery": "alle",
  "JobsDialogTimingDays": "Tage",
  "JobsDialogTimingEveryWeekday": "Jeden Wochentag(Mo-Fr)",
  "JobsDialogTimingEveryWeekOn": "Jede Woche am",
  "JobsDialogTimingOnThe": "Am",
  "JobsDialogTimingDay": "Tag",
  "JobsDialogTimingMonths": "Monat(e)",
  "JobsDialogTimingWeek": "Woche",
  "JobsDialogTimingHours": "Uhr",
  "JobsDialogTimingAt": "Um",
  "JobsDialogResources": "Ressourcen",
  "JobsDialogResourcesSelectDepartments": "Abteilungen auswählen",
  "JobsDialogResourcesName": "Ressourcenname",
  "JobsDialogCreateJobButtonText": "Job erstellen",
  "JobsDialogUpdateTimingButtonText": "Zeitplan aktualisieren",
  "JobsDialogUpdateResourcesButtonText": "Ressourcen aktualisieren",
  "UserDetailSelectRolesPlaceholder": "Rollen auswählen",
  "AddTenantTitle": "Mandant hinzufügen",
  "AddTenantTenantStepTitle": "Mandantendetails",
  "AddTenantClientIdStepTitle": "Client-ID",
  "AddTenantClientSecretStepTitle": "Client-Geheimnis",
  "AddTenantConfirmStepTitle": "Bestätigung",
  "AddTenantTenantInfoText": "Mandanteninformationen eingeben",
  "AddTenantClientIdInfoText": "Client-ID der Azure Active Directory Applikation des Mandanten eingeben",
  "AddTenantClientSecretInfoText": "Client-Geheimnis für den Mandanten eingeben",
  "AddTenantConfirmClientId": "Client ID",
  "AddTenantConfirmClientSecret": "Client Geheimnis",
  "AddTenantNextButtonText": "Weiter",
  "AddTenantCreateTenantButtonText": "Mandant erstellen",
  "LogFilterTimeRange": "Zeitbereich",
  "LogFilterTimeRanges": [
    "Letzten 30 Minuten",
    "Letzte Stunde",
    "Letzten 4 Stunden",
    "Letzten 12 Stunden",
    "Letzten 24 Stunden",
    "Letzten 48 Stunden",
    "Letzten 3 Tage",
    "Letzten 7 Tage"
  ],
  "LogFilterNoFilterKeyword": "Alle",
  "LogFilterObjectNameMissingErrorMesssage": "Bitte gültigen Objektnamen eingeben. Geben Sie 'Alle' ein wenn Sie nicht nach Objektnamen filtern möchten.",
  "LogFilterActorNameMissingErrorMesssage": "Bitte gültigen Akteur-Namen eingeben. Geben Sie 'Alle' ein wenn Sie nicht nach Akteur-Namen filtern möchten.",
  "LogFilterActorIdMissingErrorMesssage": "Bitte gültige Akteur-ID eingeben. Geben Sie 'Alle' ein wenn Sie nicht nach Akteur-ID filtern möchten.",
  "LogFilterDepartmentNameMissingErrorMesssage": "Bitte gültigen Namen für #DepartmentSingular# eingeben. Geben Sie 'Alle' ein wenn Sie nicht nach #DepartmentPlural# filtern möchten.",
  "LogFilterOrganizationActionNames": [
    "Alle",
    "#UserSingular# hinzugefügt",
    "#UserSingular# gelöscht",
    "Resource hinzugefügt",
    "Resource gelöscht",
    "VM gestartet",
    "VM neugestartet",
    "VM gestoppt",
    "Aufgabe hinzugefügt",
    "Aufgabe gelöscht",
    "#DepartmentSingular# hinzugefügt",
    "#DepartmentSingular# gelöscht",
    "#DepartmentSingular# umbenannt",
    "Mandant hinzugefügt",
    "Mandant gelöscht",
    "#AdminSingular# hinzugefügt",
    "#AdminSingular# gelöscht"
  ],
  "LogFilterOrganizationObjectTypes": [
    "Alle",
    "Virtuelle Maschine",
    "#UserSingular#",
    "#DepartmentSingular#",
    "Aufgabe",
    "#AdminSingular#",
    "Mandant"
  ],
  "LogFilterDepartmentActionNames": [
    "Alle",
    "#UserSingular# hinzugefügt",
    "#UserSingular# gelöscht",
    "Resource hinzugefügt",
    "Resource gelöscht",
    "VM gestartet",
    "VM neugestartet",
    "VM gestoppt"
  ],
  "LogFilterDepartmentObjectTypes": [
    "Alle",
    "Virtuelle Maschine",
    "#UserSingular#"
  ],
  "UserDashboardTitle": "Übersicht",
  "UserDashboardTitleExtern": "Extern",
  "UserDashboardTitleWeblinks": "Weblinks",
  "UserDashboardTitleM365": "M365",
  "UserDashboardTitleFavorite": "Favoriten",
  "ResetPasswordTitle": "Passwort zurücksetzen",
  "ResetPasswordMember": "Nutzer",
  "ResetPasswordEnterPassword": "Neues Passwort",
  "ResetPasswordConfirmPassword": "Passwort bestätigen",
  "ResetPasswordConfirmPasswordLabel": "Passwort wiederholen",
  "ResetPasswordAutoGeneratedPassword": "Automatisch generiertes Passwort",
  "ResetPasswordNewPasswordInformationText": "Hier lässt sich das Passwort eines Benutzers ändern. Geben Sie das neue temporäre Passwort im „Neues Passwort“ Feld ein. Hinweis: Das neue temporäre Passwort muss mindestens acht Zeichen umfassen. Wiederholen sie das eingegebene Passwort im „Passwort Bestätigen“ Feld. Bestätigen Sie nun die Aktion mit dem „Passwort zurücksetzen“ Button. Das neue temporäre Passwort kann auch automatisch generiert werden. Dazu setzen Sie bitte den Haken bei „automatisch generiertem Passwort“ und bestätigen Sie mit dem „Passwort zurücksetzen“ Button. Mit Hilfe des temporären Passworts kann sich der Benutzer nun ein neues individuelles Passwort erstellen.",
  "ResetPasswordCopyToClipboard": "Passwort wurde in die Zwischenablage kopiert!",
  "ResetPasswordResponse": "Passwort des Benutzers:",
  "ResetPasswordNewPwResponse": "Neues Passwort:",
  "ResetPasswordSuccessResponse": "wurde erfolgreich zurückgesetzt.",
  "ResetPasswordTempPwResponse": "Temporäres Passwort des Benutzers:",
  "ResetPasswordErrorResponse": "konnte nicht zurückgesetzt werden.",
  "InputEMailPlaceholder": "E-Mail",
  "InputErrorInvalidEMail": "Bitte geben Sie eine gültige E-Mail-Adresse ein!",
  "CostControlApiKeyNotAvailable": "API-Schlüssel wurde bereits angezeigt",
  "CostControlAddApiKeyButtonText": "API-Schlüssel hinzufügen",
  "CostControlTotalBudget": "Gesamtbudget",
  "CostControlMonthlyLimit": "Monatliches Limit",
  "CostControlThreshold": "Schwellenwert",
  "CostControlAvailableBudget": "Verfügbares Budget",
  "CostControlCurrentBalance": "Verbrauch diesen Monats",
  "CostControlUserRolesAllowedToEditMonthlyLimit": "#UserSingular#rollen, die das monatliche Limit bearbeiten dürfen",
  "CostControlUserRolesAllowedToEditApiKeys": "#UserSingular#rollen, die Api Keys bearbeiten dürfen",
  "CostControlUserRolesAllowedToEditAiModels": "#UserSingular#rollen, die AI-Modelle bearbeiten dürfen",
  "CostControlIsUserAllowedToManageApiKeys": "Dürfen #UserPlural# API-Schlüssel verwalten?",
  "CostControlUserMonthlyLimitDefault": "Start Limit für #UserPlural#",
  "CostControlCopyApiKeyWarning": "Der API-Schlüssel wird nur einmal angezeigt. Bitte kopieren Sie ihn in die Zwischenablage, um ihn zu speichern.",
  "CostControlDeleteApiKeyConfirmationHeader": "API-Schlüssel löschen",
  "CostControlDeleteApiKeyConfirmationMessage": "Möchten Sie den API-Schlüssel",
  "CostControlDeleteApiKeyConfirmationMessage2": "wirklich löschen",
  "CostControlActiveUserDepartment": "Kostenbuchen auf dieser #DepartmentSingular# aktiv",
  "CostControlSelectAiModelsHeader": "KI-Modelle auswählen",
  "CoDaPDFExportTitle": "Kostenübersicht",
  "CoDaPDFExportDateRange": "Zeitraum",
  "CoDaPDFExportCostPositionTableHeader": "Kostenstelle",
  "CoDaPDFExportCostHeader": "Kosten",
  "CoDaPDFExportTotalCostPosition": "Gesamt",
  "CoDaCostTrendsTabTitle": "Kostentrends",
  "CoDaDepartmentTrendsTabTitle": "Abteilungstrends",
  "CoDaUserAnalysisTabTitle": "Nutzeranalyse",
  "CoDaDepartmentAnalysisTabTitle": "Abteilungsanalyse",
  "CoDaSimpleInfoPanelTotalCostHeader": "Gesamtkosten",
  "CoDaSimpleInfoPanelActiveUsersHeader": "Aktive Nutzer",
  "CoDaSimpleInfoPanelCostPerUserHeader": "Kosten pro Nutzer",
  "CoDaInfoCardUserInformationHeader": "Nutzer Information",
  "CoDaInfoCardDepartmentInformationHeader": "Abteilungs Information",
  "CoDaInfoCardDepartmentsCategory": "Abteilungen",
  "CoDaInfoCardAIModelCategory": "AI Modelle",
  "CoDaConsumptionRequestsHeader": "Verbrauch & Anfragen",
  "CoDaConsumptionRequestsConsumption": "Verbrauch",
  "CoDaConsumptionRequestsRequests": "Anfragen",
  "CoDaCostTrendsTitle": "Kostentrends",
  "CoDaCostTrendsSubtitle": "Kostenentwicklung über den ausgewählten Zeitraum",
  "CoDaCostTrendsDataLabel": "Kosten in",
  "CoDaDepartmentAnalysisTitle": "Abteilungsanalyse",
  "CoDaDepartmentAnalysisSubtitle": "Analyse der Kosten und Anfragen für die ausgewählte Abteilung",
  "CoDaDepartmentAnalysisDataLabel": "Anfragen",
  "CoDaUserAnalysisTitle": "Nutzeranalyse",
  "CoDaUserAnalysisSubtitle": "Analyse der Kosten und Anfragen für den ausgewählten Nutzer",
  "CoDaUserAnalysisDataLabel": "Anfragen",
  "CoDaDepartmentCostDistributionTitle": "Abteilungskostenaufteilung",
  "CoDaDepartmentCostDistributionSubtitle": "Abteilungskosten sortiert nach Gesamtkosten",
  "CoDaDepartmentCostDistributionDatasetName": "Gesamtkosten pro Abteilung",
  "CoDaDepartmentDetailTableTitle": "Abteilungs Übersicht",
  "CoDaDepartmentDetailTableSubtitle": "Übersicht der Abteilungskosten und Anfragen",
  "CoDaDepartmentDetailTableDepartmentHeader": "Abteilung",
  "CoDaDepartmentDetailTableCostHeader": "Kosten",
  "CoDaDepartmentDetailTableRequestsHeader": "Anfrage",
  "CoDaModelUsageHeader": "Analyse der Top Modellnutzung",
  "CoDaModelUsageUserButton": "Nutzer",
  "CoDaModelUsageAiModelButton": "Ki Modelle",
  "TableHeaderName": "Name",
  "TableHeaderDescription": "Beschreibung",
  "TableHeaderLastAccessed": "Zuletzt zugegriffen am",
  "TableHeaderInvitedAt": "Eingeladen am",
  "TableHeaderType": "Typ",
  "TableHeaderAction": "Aktion",
  "TableHeaderTimestamp": "Zeitstempel",
  "TableHeaderDepartmentName": "Abteilungsname",
  "TableHeaderActionName": "Aktionsname",
  "TableHeaderObjectName": "Objektname",
  "TableHeaderObjectType": "Objekttyp",
  "TableHeaderActorName": "Akteur",
  "TableHeaderActorId": "Akteur-ID",
  "TableHeaderLastLogin": "Letzte Anmeldung",
  "TableHeaderAlerts": "Hinweis",
  "TableHeaderActions": "Aktionen",
  "TableHeaderStatus": "Status",
  "TableHeaderRoles": "Rollen",
  "TableFooterItemsPerPage": "Elemente pro Seite",
  "TableHeaderCreatedAt": "Erstellt am",
  "TableHeaderApiKey": "API-Schlüssel",
  "ButtonTextCreate": "Erstellen",
  "ButtonTextCancel": "Abbrechen",
  "ButtonTextClose": "Schließen",
  "ButtonTextInvite": "Einladen",
  "ButtonTextUpdate": "Aktualisieren",
  "ButtonTextDelete": "Löschen",
  "ButtonTextAdd": "Hinzufügen",
  "ButtonTextApplyFilter": "Filter anwenden",
  "ButtonTextResetPassword": "Passwort zurücksetzen",
  "ButtonTextBack": "Zurück",
  "ButtonTextDisabledInvite": "User bereits eingeladen",
  "ButtonTextDisabledInviteExtern": "Externer User",
  "ActionResponseSuccessStatus": "Erfolgreich",
  "ActionResponseErrorStatus": "Fehler",
  "ActionResponseStatus": "Status",
  "DeletePopUpHeader": "löschen",
  "DeletePopUpTextStart": "Möchten Sie die ausgewählten",
  "DeletePopUpTextEnd": "wirklich löschen?",
  "UpdatePopUpHeader": "aktualisieren",
  "UpdatePopUpTextStart": "Möchten Sie den ausgewählten",
  "UpdatePopUpTextEnd": "wirklich aktualisieren?",
  "EditEmailContent": "Hier können Sie die den Email Header und den Inhalt der Einladungsemail für Benutzer und Admin editieren. Sie können folgenden Tags mit ${tag} nutzen: [userName, targetOrganization, targetDepartment]",
  "Error": "Fehler:",
  "ErrorTextBackend": "Server fehler! Bitte verständigen Sie den Support!",
  "ErrorTextMissingPermissions": "Ihnen fehlen die nötigen Berechtigungen!",
  "ErrorTextTenantID": "TenantId konnte nicht gefunden werden! Bitte wenden Sie sich an einen Administrator!",
  "ErrorTextUnknownDepartment": "konnte nicht gefunden werden",
  "ErrorTextUnknownUser": "Unbekannter Benutzer!",
  "ErrorTextUnknownUserID": "Unbekannte Benutzer-ID:",
  "ErrorTextUserNotFound": "Es konnte kein Nutzer gefunden werden!",
  "ErrorTextUnableToProcessRequest": "Anfrage konnte nicht bearbeitet werden!",
  "ErrorTextRequired": "Bitte einen Wert eingeben",
  "ErrorTextNameRequired": "Bitte einen gültigen Namen eingeben",
  "ErrorTextMinLength": "Das Passwort muss mindestens 8 Zeichen lang sein",
  "ErrorTextMaxNameLength": "Maximal 20 Zeichen",
  "ErrorTextMaxDescriptionLength": "Maximal 256 Zeichen",
  "ErrorTextUnsuppotedCharactersInName": "Nur alphanumerische Zeichen, Unterstriche und Bindestriche sind zulässig",
  "ErrorTextUnsuppotedCharactersInNameShort": "Zeichen: 0-9 a-z A-Z _ -",
  "ErrorTextPasswordsDoNotMatch": "Die Passwörter stimmen nicht überein",
  "ErrorTextEnterNewPasswordFirst": "Zuerst ein neues Passwort eingeben",
  "ErrorTextNoConsumptionData": "Aktuell liegen noch keine Verbrauchsdaten vor",
  "ErrorTextDescriptionRequired": "Bitte eine gültige Beschreibung eingeben",
  "CostManagementTimeRange": "Zeitraum",
  "CostManagementTotal": "Gesamt",
  "CostManagementTokens": "Token",
  "CostManagementTokensTotal": "Gesamt",
  "CostManagementTokensRelative": "Relativ",
  "CostManagementModels": "Modelle",
  "CostManagementModel": "Modell",
  "CostManagementFilter": "Filter",
  "CostManagementSelectFilter": "Filter auswählen",
  "CostManagementSelectValue": "Wert auswählen",
  "CostManagementFilterApply": "Filter anwenden",
  "CostManagementExport": "Exportieren",
  "CostManagementRequests": "Anfragen",
  "CostManagementRequest": "Anfrage",
  "CostManagementResponse": "Antwort",
  "CostManagementRequestResponseCost": "Anfrage/Antwort Kosten",
  "CostManagementTime": "Zeit",
  "January": "Januar",
  "February": "Februar",
  "March": "März",
  "April": "April",
  "May": "Mai",
  "June": "Juni",
  "July": "Juli",
  "August": "August",
  "September": "September",
  "October": "Oktober",
  "November": "November",
  "December": "Dezember",
  "MondayMin": "Mo",
  "TuesdayMin": "Di",
  "WednesdayMin": "Mi",
  "ThursdayMin": "Do",
  "FridayMin": "Fr",
  "SaturdayMin": "Sa",
  "SundayMin": "So",
  "Monday": "Montag",
  "Tuesday": "Dienstag",
  "Wednesday": "Mittwoch",
  "Thursday": "Donnerstag",
  "Friday": "Freitag",
  "Saturday": "Samstag",
  "Sunday": "Sonntag",
  "DaysArray": [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag"
  ],
  "WeeksNumbered": [
    "Erste",
    "Zweite",
    "Dritte",
    "Vierte",
    "Fünfte"
  ],
  "VmMetainformationMetainformation": "Metainformationen",
  "VmMetainformationVm": "VM",
  "VmMetainformationStatus": "Status:",
  "VmMetainformationAdminUsername": "Admin Benutzername:",
  "VmMetainformationHardware": "Hardware",
  "VmMetainformationVCpuCores": "vCPU-Kerne:",
  "VmMetainformationRam": "RAM:",
  "VmMetainformationDataDiskSize": "Datenträgergröße:",
  "VmMetainformationDataDisks": "Datenträger-Anzahl:",
  "VmMetainformationOsDiskSize": "Datenträgergröße:",
  "VmMetainformationVmSize": "VM-Größe:",
  "VmMetainformationSoftware": "Software",
  "VmMetainformationOperatingSystem": "Betriebssystem:",
  "VmMetainformationOsVersion": "Version:",
  "VmMetainformationAzure": "Azure",
  "VmMetainformationRegion": "Region:",
  "VmMetainformationResourceGroup": "Ressourcengruppe:",
  "VmAlertsStopping": "Bitte warten, während die VM gestoppt wird",
  "VmAlertsDeallocating": "Bitte warten, während die VM deallokiert wird",
  "VmAlertsStarting": "Bitte warten, während die VM gestartet wird",
  "VmAlertsStopped": "VM verursacht Kosten!",
  "SearchPlaceholder": "Suche",
  "NavBarCollapse": "Einklappen",
  "InvitationIndicatorUsername": "Einladung wird bearbeitet...",
  "InvitationFailedIndicatorUsername": "Einladung fehlgeschlagen...",
  "TemplateSpecsAddTemplateButtonText": "Template Sepc hinzufügen",
  "TemplateSpecDescription": "Beschreibung:",
  "TemplateSpecResourceType": "Ressourcen-Type:",
  "TemplateSpecInformationCardTitle": "Informationen",
  "TemplateSpecTableHeaderVersion": "Versionen",
  "DeleteTemplateSpecHeader": "Möchten Sie die ausgewählte Template Spec löschen?",
  "ConfigEmailHeaderLabel": "E-Mail Betreff",
  "ConfigUserTypeLabel": "Nutzertyp",
  "ToastSuccessTitle": "Erfolgreich",
  "ToastErrorTitle": "Fehler",
  "ToastInformationTitle": "Information",
  "ToastWarningTitle": "Warnung",
  "ToastAdminInviteSuccessTitle": "#AdminSingular# erfolgreich hinzugefügt",
  "ToastAdminInviteErrorTitle": "#AdminSingular# hinzufügen fehlgeschlagen",
  "ToastAdminDeleteSuccessTitle": "#AdminSingular# erfolgreich gelöscht",
  "ToastAdminDeleteErrorTitle": "#AdminSingular# löschen fehlgeschlagen",
  "ToastUserInviteSuccessTitle": "#UserSingular# erfolgreich hinzugefügt",
  "ToastUserInviteErrorTitle": "#UserSingular# hinzufügen fehlgeschlagen",
  "ToastUserUpdateSuccessTitle": "#UserSingular# erfolgreich aktualisiert",
  "ToastUserUpdateErrorTitle": "#UserSingular# aktualisieren fehlgeschlagen",
  "ToastUserDeleteSuccessTitle": "#UserSingular# erfolgreich gelöscht",
  "ToastUserDeleteErrorTitle": "#UserSingular# löschen fehlgeschlagen",
  "ToastResourceAddSuccessTitle": "Ressource erfolgreich hinzugefügt",
  "ToastResourceAddErrorTitle": "Ressource hinzufügen fehlgeschlagen",
  "ToastJobAddSuccessTitle": "Job erfolgreich hinzugefügt",
  "ToastJobAddErrorTitle": "Job hinzufügen fehlgeschlagen",
  "ToastJobUpdateSuccessTitle": "Job erfolgreich aktualisiert",
  "ToastJobUpdateErrorTitle": "Job aktualisieren fehlgeschlagen",
  "ToastJobDeleteSuccessTitle": "Job erfolgreich gelöscht",
  "ToastJobDeleteErrorTitle": "Job löschen fehlgeschlagen",
  "ToastDepartmentCreateSuccessTitle": "#DepartmentSingular# erfolgreich hinzugefügt",
  "ToastDepartmentCreateErrorTitle": "#DepartmentSingular# hinzufügen fehlgeschlagen",
  "ToastDepartmentDescriptionUpdateSuccessTitle": "#DepartmentSingular# Beschreibung erfolgreich aktualisiert",
  "ToastDepartmentDescriptionUpdateErrorTitle": "#DepartmentSingular# Beschreibung aktualisieren fehlgeschlagen",
  "ToastDepartmentDeleteSuccessTitle": "#DepartmentSingular# erfolgreich gelöscht",
  "ToastDepartmentDeleteErrorTitle": "#DepartmentSingular# löschen fehlgeschlagen",
  "ToastConfigUpdateSuccessTitle": "Konfiguration erfolgreich aktualisiert",
  "ToastConfigUpdateErrorTitle": "Konfiguration aktualisieren fehlgeschlagen",
  "ToastPrivateGptUpdateSuccessTitle": "Private GPT Konfiguration erfolgreich aktualisiert",
  "ToastPrivateGptUpdateErrorTitle": "Private GPT Konfiguration aktualisieren fehlgeschlagen",
  "ToastPrivateGptCostExportSuccessTitle": "Private GPT Kosten erfolgreich exportiert",
  "ToastPrivateGptCostExportErrorTitle": "Private GPT Kosten exportieren fehlgeschlagen",
  "ToastPrivateGptPatCopySuccessTitle": "PAT erfolgreich kopiert",
  "ToastCostControlApiKeyAddSuccessTitle": "API-Schlüssel erfolgreich erstellt",
  "ToastCostControlApiKeyAddErrorTitle": "API-Schlüssel konnte nicht erstellt werden",
  "ToastCostControlApiKeyDeleteSuccessTitle": "API-Schlüssel erfolgreich gelöscht",
  "ToastCostControlApiKeyDeleteErrorTitle": "API-Schlüssel konnte nicht gelöscht werden",
  "ToastCostControlApiKeyCopySuccessTitle": "API-Schlüssel erfolgreich kopiert",
  "ToastCostControlCostManagementDataUpdateSuccessTitle": "Kostenmanagementdaten erfolgreich aktualisiert",
  "ToastCostControlCostManagementDataUpdateErrorTitle": "Kostenmanagementdaten aktualisieren fehlgeschlagen",
  "ToastLogFilterApplySuccessTitle": "Filter erfolgreich angewendet",
  "ToastLogFilterApplyErrorTitle": "Filter anwenden fehlgeschlagen",
  "ToastLogFilterApplyNoDataTitle": "Keine Daten für Filter gefunden",
  "ToastVmRdpFileUnavailableTitle": "RDP-Datei nicht verfügbar",
  "ToastVmStartingTitle": "VM wird gestartet",
  "ToastVmStoppingTitle": "VM wird gestoppt",
  "ToastVmRestartingTitle": "VM wird neugestartet",
  "PrimeNgFirstPage": "Erste Seite",
  "PrimeNgLastPage": "Letzte Seite",
  "PrimeNgNextPage": "Nächste Seite",
  "PrimeNgPreviousPage": "Vorherige Seite",
  "PrimeNgRowsPerPage": "Einträge pro Seite",
  "PrimeNgStartsWith": "Beginnt mit",
  "PrimeNgContains": "Enthält",
  "PrimeNgNotContains": "Enthält nicht",
  "PrimeNgEndsWith": "Endet mit",
  "PrimeNgEquals": "Gleich",
  "PrimeNgNotEquals": "Nicht gleich",
  "PrimeNgNoFilter": "Kein Filter",
  "PrimeNgLt": "Weniger als",
  "PrimeNgLte": "Weniger als oder gleich",
  "PrimeNgGt": "Größer als",
  "PrimeNgGte": "Größer als oder gleich",
  "PrimeNgIs": "Ist",
  "PrimeNgIsNot": "Ist nicht",
  "PrimeNgBefore": "Vor",
  "PrimeNgAfter": "Nach",
  "PrimeNgDateIs": "Datum ist",
  "PrimeNgDateIsNot": "Datum ist nicht",
  "PrimeNgDateBefore": "Datum vor",
  "PrimeNgDateAfter": "Datum nach",
  "PrimeNgClear": "Löschen",
  "PrimeNgApply": "Anwenden",
  "PrimeNgMatchAll": "Alle treffen",
  "PrimeNgMatchAny": "Eines treffen",
  "PrimeNgAddRule": "Regel hinzufügen",
  "PrimeNgRemoveRule": "Regel entfernen",
  "PrimeNgAccept": "Akzeptieren",
  "PrimeNgReject": "Ablehnen",
  "PrimeNgChoose": "Wählen",
  "PrimeNgUpload": "Hochladen",
  "PrimeNgCancel": "Abbrechen",
  "PrimeNgFileSizeTypes": [
    "Bytes",
    "KB",
    "MB",
    "GB"
  ],
  "PrimeNgDayNames": [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag"
  ],
  "PrimeNgDayNamesShort": [
    "So",
    "Mo",
    "Di",
    "Mi",
    "Do",
    "Fr",
    "Sa"
  ],
  "PrimeNgDayNamesMin": [
    "So",
    "Mo",
    "Di",
    "Mi",
    "Do",
    "Fr",
    "Sa"
  ],
  "PrimeNgMonthNames": [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ],
  "PrimeNgMonthNamesShort": [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez"
  ],
  "PrimeNgDateFormat": "dd.mm.yyyy",
  "PrimeNgFirstDayOfWeek": 1,
  "PrimeNgToday": "Heute",
  "PrimeNgWeekHeader": "Wo",
  "PrimeNgWeak": "Schwach",
  "PrimeNgMedium": "Mittel",
  "PrimeNgStrong": "Stark",
  "PrimeNgPasswordPrompt": "Geben Sie ein Passwort ein",
  "PrimeNgEmptyMessage": "Keine Ergebnisse gefunden",
  "PrimeNgEmptyFilterMessage": "Keine Ergebnisse gefunden",
  "PrimeNgPending": "Ausstehend",
  "PrimeNgChooseYear": "Wählen Sie ein Jahr",
  "PrimeNgChooseMonth": "Wählen Sie einen Monat",
  "PrimeNgChooseDate": "Wählen Sie ein Datum",
  "PrimeNgPrevDecade": "Vorheriges Jahrzehnt",
  "PrimeNgNextDecade": "Nächstes Jahrzehnt",
  "PrimeNgPrevYear": "Vorheriges Jahr",
  "PrimeNgNextYear": "Nächstes Jahr",
  "PrimeNgPrevMonth": "Vorheriger Monat",
  "PrimeNgNextMonth": "Nächster Monat",
  "PrimeNgPrevHour": "Vorherige Stunde",
  "PrimeNgNextHour": "Nächste Stunde",
  "PrimeNgPrevMinute": "Vorherige Minute",
  "PrimeNgNextMinute": "Nächste Minute",
  "PrimeNgPrevSecond": "Vorherige Sekunde",
  "PrimeNgNextSecond": "Nächste Sekunde",
  "PrimeNgAm": "AM",
  "PrimeNgPm": "PM",
  "PrimeNgSearchMessage": "{0} Ergebnisse sind verfügbar",
  "PrimeNgSelectionMessage": "{0} Elemente ausgewählt",
  "PrimeNgEmptySelectionMessage": "Kein ausgewähltes Element",
  "PrimeNgEmptySearchMessage": "Keine Ergebnisse gefunden",
  "PrimeNgAria": {
    "trueLabel": "Wahr",
    "falseLabel": "Falsch",
    "nullLabel": "Nicht ausgewählt",
    "star": "1 Stern",
    "stars": "{0} Sterne",
    "selectAll": "Alles auswählen",
    "unselectAll": "Alles abwählen",
    "close": "Schließen",
    "previous": "Vorherige",
    "next": "Nächste",
    "navigation": "Navigation",
    "scrollTop": "Nach oben scrollen",
    "moveTop": "Nach oben bewegen",
    "moveUp": "Nach oben",
    "moveDown": "Nach unten",
    "moveBottom": "Nach unten bewegen",
    "moveToTarget": "Zum Ziel bewegen",
    "moveToSource": "Zur Quelle bewegen",
    "moveAllToTarget": "Alles zum Ziel bewegen",
    "moveAllToSource": "Alles zur Quelle bewegen",
    "pageLabel": "Seite",
    "firstPageLabel": "Erste Seite",
    "lastPageLabel": "Letzte Seite",
    "nextPageLabel": "Nächste Seite",
    "prevPageLabel": "Vorherige Seite",
    "rowsPerPageLabel": "Zeilen pro Seite",
    "previousPageLabel": "Vorherige Seite",
    "jumpToPageDropdownLabel": "Zur Seite springen Dropdown",
    "jumpToPageInputLabel": "Zur Seite springen Eingabe",
    "selectRow": "Zeile auswählen",
    "unselectRow": "Zeile abwählen",
    "expandRow": "Zeile erweitern",
    "collapseRow": "Zeile reduzieren",
    "showFilterMenu": "Filtermenü anzeigen",
    "hideFilterMenu": "Filtermenü verbergen",
    "filterOperator": "Filteroperator",
    "filterConstraint": "Filtereinschränkung",
    "editRow": "Zeile bearbeiten",
    "saveEdit": "Bearbeitung speichern",
    "cancelEdit": "Bearbeitung abbrechen",
    "listView": "Listenansicht",
    "gridView": "Gitteransicht",
    "slide": "Rutsche",
    "slideNumber": "Rutsche {0}",
    "zoomImage": "Bild zoomen",
    "zoomIn": "Vergrößern",
    "zoomOut": "Verkleinern",
    "rotateRight": "Rechts drehen",
    "rotateLeft": "Links drehen",
    "listLabel": "Liste",
    "selectColor": "Farbe auswählen",
    "removeLabel": "Entfernen",
    "browseFiles": "Dateien durchsuchen",
    "maximizeLabel": "Maximieren"
  }
}
