// FOR PROD (NO STRING COMPARE)
export const FEATURE_FLAGS = {
  IS_USER_PORTAL_ENABLED: 0,
  IS_DEPARTMENTS_DYNAMIC: 1,
  IS_CUSTOM_ROLE_ENABLED: 2,
  IS_RESOURCES_ENABLED: 3,
  IS_CRON_JOBS_ENABLED: 4,
  IS_MONITORING_ENABLED: 5,
  IS_TEMPLATE_SPECS_ENABLED: 6,
  IS_PRIVATE_GPT_ENABLED: 7,
  IS_COST_CONTROL_API_ENABLED: 8,
  IS_COST_CONTROL_UI_ENABLED: 9,
  IS_USER_EMAIL_NOTIFICATION_ON_INVITE_ENABLED: 10,
  IS_ADMIN_EMAIL_NOTIFICATION_ON_INVITE_ENABLED: 11,
  IS_USER_PORTAL_COMMUNITY_TILE_ENABLED: 12,
  IS_ADMIN_PORTAL_COMMUNITY_TILE_ENABLED: 13,
} as const;

// FOR DEBUGGING
/*export const FEATURE_FLAGS = {
  IS_USER_PORTAL_ENABLED: 'isUserPortalEnabled',
  IS_DEPARTMENTS_DYNAMIC: 'isDepartmentsDynamic',
  IS_CUSTOM_ROLE_ENABLED: 'isCustomRoleEnabled',
  IS_RESOURCES_ENABLED: 'isResourcesEnabled',
  IS_CRON_JOBS_ENABLED: 'isCronJobsEnabled',
  IS_MONITORING_ENABLED: 'isMonitoringEnabled',
  IS_TEMPLATE_SPECS_ENABLED: 'isTemplateSpecsEnabled',
  IS_PRIVATE_GPT_ENABLED: 'isPrivateGptEnabled',
  IS_COST_CONTROL_API_ENABLED: 'isCostControlApiEnabled',
  IS_COST_CONTROL_UI_ENABLED: 'isCostControlUiEnabled',
  IS_USER_EMAIL_NOTIFICATION_ON_INVITE_ENABLED: 'isUserEmailNotificationOnInviteEnabled',
  IS_ADMIN_EMAIL_NOTIFICATION_ON_INVITE_ENABLED: 'isAdminEmailNotificationOnInviteEnabled',
  IS_USER_PORTAL_COMMUNITY_TILE_ENABLED: 'isUserPortalCommunityTileEnabled',
  IS_ADMIN_PORTAL_COMMUNITY_TILE_ENABLED: 'isAdminPortalCommunityTileEnabled',
} as const;*/
